<template>
  <div>
    <validation-observer ref="ACForm">
      <b-form>
        <b-card
          :title="
            $t(
              'g.AddEmployeesAuthorizedToDealWithComputersRelatedToOperationAndQualityManagementSystem'
            )
          "
        >
          <b-row>
            <!-- Select The Employee -->
            <b-col md="4">
              <b-form-group :label="$t('g.employee')">
                <validation-provider
                  #default="{ errors }"
                  name="employee"
                  rules="required"
                >
                  <b-form-select
                    v-model="form_data.employee"
                    :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                    :options="employee_options"
                    label="employeeName"
                    :state="errors.length > 0 ? false : null"
                  />

                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Select The Employee -->
            <!-- Computer Code  -->
            <b-col md="4">
              <b-form-group :label="$t('g.computerCode')">
                <validation-provider
                  #default="{ errors }"
                  name="computer code"
                  rules="required"
                >
                  <b-form-input
                    v-model="form_data.computer_code"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('g.placeholder.enterTheComputerCodeHere')"
                  />
                  <small class="text-danger">{{
                    errors[0] ? $t("g.alert." + errors[0]) : errors[0]
                  }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- ** Computer Code  -->
            <!-- Notes -->
            <b-col md="12">
              <b-form-group :label="$t('g.notes')">
                <b-form-textarea
                  id="textarea-default"
                  :placeholder="$t('g.placeholder.enterTheNotesHere')"
                  v-model="form_data.notes"
                  rows="5"
                />
              </b-form-group>
            </b-col>
            <!-- ** Notes -->
            <b-col cols="12">
              <b-button variant="primary" @click.prevent="validateForm">
                {{ $t("g.submit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm,
  BRow,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormTextarea,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCard,
    BCol,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormTextarea,
    BButton,
  },
  data() {
    return {
      form_data: {
        employee: null,
        computer_code: null,
        notes: null,
      },
      // ! This is options for test , please reset array when call employee data
      employee_options: [
        {
          value: null,
          text: this.$t("g.selectEmployee"),
          disabled: true,
        },
        "Employee 1",
        "Employee 2",
        "Employee 3",
        "Employee 4",
        "Employee 5",
      ],
    };
  },
  methods: {
    clearForm() {
      let form = this.form_data,
        i;
      for (i in form) {
        // console.log(i);
        form[i] = null;
      }
    },
    //  Sweet Toast Function config
    makeToast(variant = null, body, title) {
      this.$bvToast.toast(this.$t(body), {
        title: this.$t(title),
        variant,
        solid: true,
      });
    },
    validateForm() {
      this.$refs.ACForm.validate().then((success) => {
        if (success) {
          this.submitForm();
          this.clearForm();
          this.$refs.ACForm.reset();
        } else {
          this.makeToast(
            "warning",
            this.$t("g.send.invalidInputBody"),
            this.$t("g.send.invalidInput")
          );
        }
      });
    },
    submitForm() {
      let formData = new FormData();
      for (let item in this.form_data) {
        formData.append(item, this.form_data[item]);
      }
      this.$http
        .post("https://api.npoint.io/bd1ce6326b271bece1de", formData)
        .then((res) => {
          this.makeToast(
            "success",
            this.$t("g.send.successBody"),
            this.$t("g.send.successTitle")
          );
        })
        .catch((err) => {
          for (const key in err.response.data.errors) {
            this.makeToast(
              "danger",
              err.response.data.errors[key][0],
              this.$t("g.send.errorTitle")
            );
          }
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
